import styled from "styled-components";

const Label = styled.span`
	font-family: Ubuntu;
	font-size: 16px;
	line-height: 26px;
    margin-bottom: 0;
	color: ${props => (props.white ? "#fff" : props.color ?? "#000")};
`;

export default Label;
