import styled from "styled-components";

const Description = styled.p`
	font-family: Ubuntu;
	font-size: 18px;
	line-height: 26px;
	text-align: ${props => (props.center ? "center" : "start")};
	color: ${props => (props.white ? "#fff" : props.color ?? "#5e5e5e")};
	margin-top: ${props => (props.smallmargin ? "4px" : "16px")};
	margin-bottom: ${props => (props.smallmargin ? "10px" : "16px")};
	font-weight: ${props => (props.fontWeight ? props.fontWeight : "normal")};
`;

export default Description;
