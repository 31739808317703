import styled from "styled-components";

const Title = styled.h3`
font-family: Ubuntu;
font-size: 20px;
font-weight: 500;
margin-bottom: 8px;
color: ${props => props.white ? "#fff" : "#000000"};
letter-spacing: 0.4px;
text-align: ${props => props.align ?? "center"};
`;

export default Title;
